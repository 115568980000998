<script setup>
import { useUserStore } from '@/stores/index.js'
import { RouterView } from 'vue-router'
import { ElConfigProvider } from "element-plus";
// 引入中文包

import locale from 'element-plus/es/locale/lang/zh-cn'
const storageUserInfo = window.localStorage.getItem('userInfo')
if (storageUserInfo) {
  const store = useUserStore()
  store.set_userInfo(JSON.parse(storageUserInfo))
}

</script>

<template>
  <el-config-provider :locale="locale">
    <!-- 添加路由组件 -->
    <router-view />
  </el-config-provider>
</template>

<style scoped lang="scss">
</style>



import { createApp } from 'vue'
import { createPinia } from 'pinia'


import { ElButton, ElSelect, ElIcon, ElInput, ElOption, ElTable, ElTableColumn, ElFormItem, ElForm, ElDialog, ElDrawer, ElSwitch, ElPagination, ElLoading } from 'element-plus'
const els = { ElButton, ElSelect, ElIcon, ElInput, ElOption, ElTable, ElTableColumn, ElFormItem, ElForm, ElDialog, ElDrawer, ElSwitch, ElPagination, ElLoading }

// import elementUi from 'element-plus'


import "element-plus/dist/index.css";
import './assets/main.css'
// import locale from 'element-plus/es/locale/lang/zh-cn'

import App from './App.vue'
import router from './router'

import contentPageFrame from './components/common/contentPageFrame.vue'
import MyTable from '@/components/forPage/table/myTable2.vue'

const app = createApp(App)
Object.keys(els).forEach(key => {
    app.component(key, els[key])
})

// app.use(elementUi)
app.component('contentPageFrame', contentPageFrame)
app.component('MyTable', MyTable)


app.use(createPinia())
app.use(router)

app.mount('#app')

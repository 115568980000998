<template>
  <div class="tableBox">
    <div class="table-wrapper">
      <el-table ref="multipleTable" v-loading="loading" :data="tableData" :[heightK]="height" style="width: 100%; "
        header-row-class-name="headerRow" row-class-name="bodyRow" :row-key="getRowKey"
        @selection-change="handleSelectionChange" table-layout="fixed">
        <template v-for="label in tableLabel" :key="label.name">
          <el-table-column v-if="!label.slot" :prop="label.name" :label="label.label" :width="label.width"
            :min-width="label.minWidth">
            <template #default="scope">
              {{ scope.row[label.name] ? scope.row[label.name] : "--" }}
            </template>
          </el-table-column>
          <slot v-else :name="label.name" :label="label"></slot>
        </template>
        <template #empty>
          <div class="nodata">
            <!-- <img src="@/assets/imgs/nodata.png" /> -->
            <span>暂无数据</span>
          </div>
        </template>
      </el-table>
    </div>
    <el-pagination v-if="showPagination" class="p" background :layout="layout" :total="total" :page-size="pageSize"
      :page-sizes="[10, 20, 50, 100]" :current-page="currentPage" @current-change="currentChange"
      @size-change="handleSizeChange"></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableLabel: {
      type: Array,
      default: () => [],
    },
    rowKey: {
      type: String,
      default: "id",
    },
    layout: {
      type: String,
      default: "total,sizes,prev, pager, next,jumper",
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: "calc(100% - 56px)",
    },
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    }
  },
  computed: {
    heightK() {
      return this.height ? "height" : null;
    },
  },
  watch: {
    tableData: {
      handler() {
        this.$refs.multipleTable.clearSelection();
        this.$emit("changeTableData");
      },
      deep: true,
    },

  },
  methods: {
    setData(key, data) {
      this[key] = data

    },

    getRowKey(row) {
      return row[this.rowKey];
    },
    currentChange(e) {
      this.currentPage = e
    },
    handleSelectionChange(e) {
      this.$emit("handleSelectionChange", e);
    },
    handleSizeChange(e) {
      this.pageSize = e
    },
  },
  mounted() {
    this.$emit('mounted')
  }
};
</script>

<style lang="scss" scoped>
.tableBox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .table-wrapper {
    flex: 1;
    overflow: hidden;
  }

  &:deep(.headerRow) {
    th {
      background-color: #EDF2FD;
      // height: 40px;
      height: 52px;
      padding: 0;

      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #343840;
      line-height: 19px;
    }
  }

  &:deep(.bodyRow) {
    td {
      // padding: 11px 0;
      padding: 18px 0;
      color: #4D5464;
      font-family: MicrosoftYaHei;
    }
  }

  &:deep(.el-scrollbar__view) {
    height: 100%;
  }

  .nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  :deep(.el-table__inner-wrapper) {
    height: 100% !important;
  }
}

.p {
  display: flex;
  justify-content: flex-end
}
</style>

<template>
    <div class="content_page_frame">
        <div class="t">{{ props.title }}</div>
        <div>
            <div class="search">
                <div class="search_wrapper">
                    <slot name="search"></slot>
                </div>
                <div class="search_reset" style="width: 140px;text-align: right;">
                    <el-button type="primary" @click="$emit('search')">查询</el-button>
                    <el-button type="plain" @click="$emit('reset')">重置</el-button>
                </div>
            </div>
            <div class="operate">
                <div class="operateLeft">
                    <slot name="operateLeft"></slot>
                </div>
                <div class="operateRight">
                    <slot name="operateRight"></slot>
                </div>
            </div>
        </div>
        <div class="table" id="tableArea">

            <slot name="table"></slot>

        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'

const props = defineProps({
    title: {
        type: String,
        default: 'Banner管理'
    }
})

const data = reactive({
    tableAreaHeight: '100%'
})

onMounted(() => {
    const tableArea = document.querySelector('#tableArea')
    // data.tableAreaHeight = tableArea.offsetHeight - 85
})

</script>

<style lang='scss' scoped>
.content_page_frame {
    display: flex;
    flex-direction: column;
    // background-color: yellow;
    height: 100%;

    .t {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #343840;
        line-height: 25px;
    }

    .search {
        padding-top: 24px;
        display: flex;
        align-items: start;
        justify-content: space-between;

        .search_wrapper {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
        }
    }

    .operate {
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;
    }

    .table {
        flex: 1;

        min-width: 0;
        overflow: hidden;
    }
}
</style>

export default [
    {
        path: '/system/bannerManage',
        name: 'bannerManage',
        component: () => import('@/views/bannerManage/bannerManage.vue'),
        icon: 'a',
        title: 'Banner管理'
    },
    {
        path: '/system/cardManage',
        name: 'cardManage',
        component: () => import('@/views/cardManage/cardManage.vue'),
        icon: 'b',
        title: '卡面管理'
    },

    {
        path: '/system/itemManage',
        name: 'itemManage',
        component: () => import('@/views/itemManage/itemManage.vue'),
        icon: 'c',
        title: '机构管理'
    },

    {
        path: '/system/itemManage',
        name: 'quite',
        // component: () => import('@/views/itemManage/itemManage.vue'),
        icon: 'd',
        title: '退出登录',
        action: 'logout',
        noAction: true
    },


]
